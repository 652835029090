<script>
export default {
  methods: {
    goToPage () {
      this.$router.push({ name: 'LoginForm' })
    }
  }
}
</script>
<template lang="pug">
  .container
    .header
      img.logo(src="@/assets/images/login/logo.svg")
    .form
      .title Se cambio la contraseña
      .text Si crees que es posible que otra persona conozca tu contraseña anterior, es conveniente que cierres la sesión en todas tus computadoras que tengas y compruebes si hubo cambios recientes en tu cuenta.
      button.btn(
        @click="goToPage"
      ) Regresar al home
</template>
<style lang="scss" scoped>
  $colorText: #006AB6;
  .container {
    background: #D2EFF7;
    height: 100vh;
  }
  .header {
    width: 100%;
    padding: 20px 0;
    .logo {
      height: 40px;
      margin: 0 0 0 100px;
    }
  }
  .form {
    display:flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 400px;
    background: white;
    border-radius: 10px;
    margin: auto;
    padding: 10px;
    .title {
      color: $colorText;
      font-size: 24px;
      margin: 10px 0 20px 0;
      font-weight: 700;
    }
    .text {
      color: $colorText;
      font-size: 14px;
      font-weight: 500;
      padding: 10px 20px;
      line-height: 1.5;
      text-align: center;
      margin: 20px 0;
    }
    .btn {
      border-radius: 50px;
      background: $colorText;
      color: white;
      padding: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      width: 50%;
      margin: 0 0 20px 0;
    }
  }
</style>
